import GlobalStateContext from "../../context/globalStateContext"
import modelData from "./cabbage-maggot.json"
import { format } from "date-fns"
import domtoimage from "dom-to-image"
import { saveAs } from "file-saver"
import React from "react"
import {
  ComposedChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  ReferenceLine,
  ReferenceDot,
  Area,
} from "recharts"

const CustomXLabel = (props) => {
  const { x, y, payload } = props
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        fontSize={11}
        textAnchor="middle"
        fill="#666"
        transform="rotate(-15)"
      >
        {payload.value}
      </text>
    </g>
  )
}

const CustomYLabel = (props) => {
  const { x, y, payload, unit } = props
  return (
    <g>
      <text
        x={x}
        y={y}
        dy={5}
        dx={-15}
        fontSize={11}
        textAnchor="middle"
        fill="#666"
      >
        {payload.value}
        {unit}
      </text>
    </g>
  )
}

const CustomizedReferenceLineLabelDate = (props) => {
  const { date, viewBox } = props
  return (
    <g>
      <text
        x={viewBox.x}
        y={viewBox.y}
        dy={-5}
        // dx={-15}
        fontSize={11}
        textAnchor="middle"
        fill="#666"
      >
        {date}
      </text>
    </g>
  )
}

const CustomizedReferenceLineLabelText = (props) => {
  const { message, viewBox } = props
  return (
    <g>
      <text
        x={viewBox.x}
        y={viewBox.y}
        dy={-5}
        dx={10}
        fontSize={13}
        textAnchor="start"
        fill="#fff"
        fontWeight="bold"
      >
        {message}
      </text>
    </g>
  )
}

const renderTooltip = ({ payload }) => {
  if (typeof payload === "undefined") return null
  const entry = payload.find((d) => d.dataKey === "gdd")
  if (entry) {
    const { date, gdd } = entry.payload
    return (
      <div
        key={date}
        className="flex flex-col px-4 py-3 bg-white rounded opacity-90"
      >
        <div>
          <span className="mr-1 text-xs font-semibold">Date:</span>
          <span className="text-xs">{format(date, "LLL do")}</span>
        </div>
        <div>
          <span className="mr-1 text-xs font-semibold">Cumulative DD:</span>
          <span className="text-xs">{gdd}</span>
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default function ResultsGraph({ data }) {
  const {
    generationBands,
    generationThresholds,
    title,
    peakEmergence,
  } = modelData.elements.resultsGraph

  const {
    overwintering,
    springEmergence,
    first,
    second,
    third,
  } = generationBands

  const {
    lowerOverwinter,
    upperOverwinter,
    lowerFirst,
    upperFirst,
    lowerSecond,
    upperSecond,
    lowerThird,
    upperThird,
  } = generationThresholds

  const { dateOfInterest } = React.useContext(GlobalStateContext)

  let dataGraph = null
  let maxGdd
  // console.log(data)

  if (data) {
    const currentDayGdd = data.slice(-6)[0].gdd
    maxGdd = data.slice(-1)[0].gdd

    if (currentDayGdd > upperThird) {
      // console.log("Fourth")
      dataGraph = data.map((d) => ({
        ...d,
        overwintering,
        springEmergence,
        first,
        second,
        third,
        fourth: maxGdd - upperThird,
      }))
    }

    if (currentDayGdd >= lowerThird && currentDayGdd <= upperThird) {
      // console.log("Third", maxGdd - upperThird)
      dataGraph = data.map((d) => ({
        ...d,
        overwintering,
        springEmergence,
        first,
        second,
        third: maxGdd - upperThird < 0 ? maxGdd - lowerThird : third,
        fourth: maxGdd - upperThird < 0 ? null : maxGdd - upperThird,
      }))
    }

    if (currentDayGdd >= lowerSecond && currentDayGdd <= upperSecond) {
      // console.log("Second")
      dataGraph = data.map((d) => ({
        ...d,
        overwintering,
        springEmergence,
        first,
        second: maxGdd - upperSecond < 0 ? maxGdd - lowerSecond : second,
        third: maxGdd - upperSecond < 0 ? null : maxGdd - upperSecond,
      }))
    }

    if (currentDayGdd >= lowerFirst && currentDayGdd <= upperFirst) {
      // console.log("First")
      dataGraph = data.map((d) => ({
        ...d,
        overwintering,
        springEmergence,
        first: maxGdd - upperFirst < 0 ? maxGdd - lowerFirst : first,
        second: maxGdd - upperFirst < 0 ? null : maxGdd - upperFirst,
      }))
    }

    if (currentDayGdd >= lowerOverwinter && currentDayGdd <= upperOverwinter) {
      // console.log("Spring Emergence", maxGdd, springEmergence)
      dataGraph = data.map((d) => ({
        ...d,
        overwintering,
        springEmergence:
          maxGdd - upperOverwinter < 0
            ? maxGdd - lowerOverwinter
            : springEmergence,
        first: maxGdd - upperOverwinter < 0 ? null : maxGdd - upperOverwinter,
      }))
    }

    if (currentDayGdd < lowerOverwinter) {
      // console.log("Overwintering", maxGdd - lowerOverwinter)
      dataGraph = data.map((d) => ({
        ...d,
        overwintering: maxGdd < 0 ? maxGdd : overwintering,
        springEmergence:
          maxGdd - lowerOverwinter < 0 ? null : maxGdd - lowerOverwinter,
      }))
    }
  }

  function downloadPNG() {
    domtoimage
      .toBlob(document.getElementById("chart"))
      .then((blob) => saveAs(blob, `${modelData.title} Chart.png`))
  }

  if (dataGraph === null) return null
  return (
    <div className="w-full" aria-hidden="true">
      <div className="flex items-center justify-between mb-6">
        <h2 className="inline-block text-lg font-semibold text-gray-600 sm:text-xl md:text-2xl">
          {title}
        </h2>

        <div className="flex items-center justify-center rounded-md">
          <button
            type="button"
            aria-label="download graph in png format"
            onClick={downloadPNG}
            className="inline-flex items-center p-2 text-sm font-bold leading-4 text-white transition duration-150 ease-in-out border border-transparent rounded-md sm:px-3 sm:py-2 bg-secondary-800 hover:bg-secondary-700 focus:outline-none focus:border-secondary-900 focus:shadow-outline-secondary active:bg-secondary-900"
          >
            <svg
              className="-ml-0.5 mr-2 h-4 w-4 text-white"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path>
            </svg>
            <span className="hidden mr-1 sm:inline-block">Download </span>{" "}
            <span className="text-white">PNG</span>
          </button>
        </div>
      </div>

      <ResponsiveContainer width="100%" height={500} id="chart">
        <ComposedChart
          data={dataGraph}
          margin={{ top: 20, right: 30, left: -30, bottom: 20 }}
          className="bg-white border-b border-gray-200 shadow sm:rounded-lg"
        >
          <XAxis
            dataKey="dateDisplay"
            interval={"preserveStartEnd"}
            axisLine={true}
            tick={<CustomXLabel />}
          ></XAxis>
          <YAxis
            width={110}
            dataKey="gdd"
            type="number"
            domain={[0, "dataMax"]}
            // ticks={[0, 390, 735, 1217]}
            tick={<CustomYLabel />}
            label={{
              value: "Accumulated Degree-Days",
              angle: -90,
              position: "center",
              offset: 0,
            }}
          />
          <Tooltip content={renderTooltip} />
          <Legend
            verticalAlign="top"
            height={100}
            align="right"
            // width={450}
            // layout="vertical"
          />
          <Area
            stackId="generation"
            type="monotone"
            dataKey="overwintering"
            fill="#fff"
            // fillOpacity="100%"
            stroke="none"
            legendType="none"
            name="overwintering"
          />
          <Area
            stackId="generation"
            type="monotone"
            dataKey="springEmergence"
            fill="#748B9C"
            fillOpacity={1}
            stroke="#748B9C"
            strokeWidth={0}
            legendType="square"
            name="Spring Emergence"
          />
          <Area
            stackId="generation"
            type="monotone"
            dataKey="first"
            fill="#9A96B8"
            fillOpacity={1}
            stroke="#9A96B8"
            strokeWidth={0}
            legendType="square"
            name="First Generation"
          />
          <Area
            stackId="generation"
            type="monotone"
            dataKey="second"
            fill="#CF9ABB"
            fillOpacity={1}
            stroke="#CF9ABB"
            strokeWidth={0}
            legendType="square"
            name="Second Generation"
          />
          <Area
            stackId="generation"
            type="monotone"
            dataKey="third"
            fill="#F4A8A4"
            fillOpacity={1}
            stroke="#F4A8A4"
            strokeWidth={0}
            legendType="square"
            name="Third Generation"
          />
          <Area
            stackId="generation"
            type="monotone"
            dataKey="fourth"
            fill="#F8CC8A"
            // fillOpacity={1}
            stroke="#F8CC8A"
            strokeWidth={0}
            legendType="square"
            name="Fourth Generation"
          />
          {maxGdd <= peakEmergence.first && (
            <ReferenceLine
              y={366}
              stroke="#fff"
              strokeDasharray="3 3"
              label={
                <CustomizedReferenceLineLabelText message={`25% Emergence`} />
              }
            />
          )}
          <ReferenceLine
            y={452}
            stroke="#fff"
            strokeDasharray="8 8"
            label={
              <CustomizedReferenceLineLabelText
                message={`Peak Overwintering Generation`}
              />
            }
          />
          {maxGdd <= peakEmergence.first && (
            <ReferenceLine
              y={547}
              stroke="#fff"
              strokeDasharray="3 3"
              label={
                <CustomizedReferenceLineLabelText message={`75% Emergence`} />
              }
            />
          )}
          {maxGdd <= peakEmergence.first && (
            <ReferenceLine
              y={697}
              stroke="#fff"
              strokeDasharray="3 3"
              label={
                <CustomizedReferenceLineLabelText message={`95% Emergence`} />
              }
            />
          )}
          <ReferenceLine
            y={peakEmergence.first}
            stroke="#fff"
            strokeDasharray="8 8"
            label={
              <CustomizedReferenceLineLabelText
                message={`Peak First Generation`}
              />
            }
          />
          <ReferenceLine
            y={peakEmergence.second}
            stroke="#fff"
            strokeDasharray="8 8"
            label={
              <CustomizedReferenceLineLabelText
                message={`Peak Second Generation`}
              />
            }
          />
          <ReferenceLine
            y={peakEmergence.third}
            stroke="#fff"
            strokeDasharray="8 8"
            label={
              <CustomizedReferenceLineLabelText
                message={`Peak Third Generation`}
              />
            }
          />
          <ReferenceLine
            x={dateOfInterest.server}
            stroke="#000"
            strokeWidth={1}
            label={
              <CustomizedReferenceLineLabelDate date={dateOfInterest.server} />
            }
          />
          <Line
            type="monotone"
            dataKey="gdd"
            stroke="#000"
            strokeWidth={2}
            dot={false}
            name="Cabbage Maggot DD (Base 40 ˚F)"
          />
          <ReferenceDot
            x={dateOfInterest.server}
            y={dataGraph[dateOfInterest.dayOfYear - 1].gdd}
            r={3}
            fill="#000"
            stroke="none"
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}
