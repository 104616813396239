import React from "react"

export default function References() {
  return (
    <>
      <p className="text-sm leading-5 text-left text-gray-500">
        Eckenrode, C. and Chapman, R.K. 1971. Effect of various temperatures
        upon rate of development of the cabbage maggot under artificial
        conditions. Ann. Entomol. Soc. Amer. 64: 1079-1083.
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">
        Eckenrode, C. and Chapman, R.K. 1972. Seasonal adult cabbage maggot
        populations in the field in relation to thermal-unit accumulations. Ann.
        Entomol. Soc. Amer. 65: 151-156.
      </p>
    </>
  )
}
