import React from "react"

export default function Acknowledgements() {
  return (
    <>
      <p className="text-sm leading-5 text-left text-gray-500">
        The NEWA cabbage maggot tool is based on data and research by C.
        Eckenrode and R.K. Chapman at Cornell University.
      </p>
    </>
  )
}
